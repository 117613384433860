import type { UseFetchOptions } from 'nuxt/app'

export function useApiFetch<T>(
  url: string | (() => string),
  options?: UseFetchOptions<T>,
) {
  // Get x-invalidate-cache request header
  const xInvalidateCache = useRequestHeader('x-invalidate-cache')

  const headers: Record<string, string> = {}
  if (xInvalidateCache) {
    headers['x-invalidate-cache'] = xInvalidateCache
  }

  return useFetch(url, {
    ...options,
    headers: { ...options?.headers, ...headers },
  })
}
